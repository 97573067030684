<template>
  <section class="main-sec">
    <header class="plan-header" hidden>
      見習計畫書(一組一份)
    </header>
    <section class="start-sec mt-3">
      <main class="main-body-header">見習計畫書(一組一份)</main>
      <main class="main-body">
        <header>一. 見習單位及時段 : (請列出最想去的前三名)</header>
        <nav class="my-areas">
          <b-form-checkbox v-for="item in areas" :key="item" :value="item" v-model="selectedAreas"> {{ item }} </b-form-checkbox>
        </nav>
      </main>
      <main class="main-body sec-2">
        <header>二. 欲報名之見習方式 :</header>
        <b-form-radio v-model="enrollBy" value="1">一般見習</b-form-radio>
        <b-form-radio v-model="enrollBy" value="2">彈性見習</b-form-radio>
      </main>
      <main class="main-body sec-3" style="height:330px">
        <header style="margin-bottom:20px;">三. 填寫可見習的月份、時間或務必排開的見習時段 :</header>
        <b-textarea placeholder="請輸入..." v-model="userMonth" spellcheck="false" style="width:850px; height:250px;transform:translateX(20px)" :no-resize="true">
        </b-textarea>
      </main>
      <main class="main-body sec-4">
        <header>四. 見習目標 : (見習目標至少兩項)</header>
        <nav class="my-areas my-target">
          <b-form-checkbox v-for="item in targets" :key="item" :value="item" v-model="selectedTargets"> {{ item }} </b-form-checkbox>
        </nav>
        <footer>
          <div class="pl-4 mt-3">內容: (內文至少300字)</div>
          <b-textarea placeholder="請輸入..." v-model="userTarget" spellcheck="false" style="width:850px; height:400px;transform:translateX(20px)" :no-resize="true">
          </b-textarea>
        </footer>
      </main>
      <main class="main-body sec-3" style="height:230px;">
        <header style="margin-bottom:20px;">五. 預期成果 : (至少100字)</header>
        <b-textarea placeholder="請輸入..." v-model="userInput" spellcheck="false" style="width:850px; height:150px;transform:translateX(20px)" :no-resize="true">
        </b-textarea>
      </main>
      <footer class="footer-btn-areas mt-3 mb-4">
        <b-button variant="primary" @click="submit">送出表單</b-button>
      </footer>
      <div style="height:80px;"></div>
    </section>
  </section>
</template>

<script>
import moment from "moment";
const areas = [
  "北/桃園市  復興區衛生所",
  "北/新北市  烏來區衛生所",
  "中/苗栗縣  泰安鄉衛生所",
  "南/高雄市  茂林區衛生所",
  "南/高雄市  桃源區衛生所",
  "南/高雄市  牡丹鄉衛生所",
  "東/花蓮縣  光復鄉衛生所",
  "東/花蓮縣  新城鄉衛生所",
  "東/花蓮縣  萬榮鄉衛生所",
  "東/花蓮縣  玉里鎮衛生所",
  "東/花蓮縣  鳳林鎮衛生所",
  "東/花蓮縣  達仁鄉衛生所",
  "離島/澎湖縣  白沙鄉衛生所",
  "離島/連江縣  北竿衛生所",
];

const targets = ["單位業務及環境認識", "家訪(居家護理、TB關懷、長照評估..等)", "跟診(門診)", "社區健康服務: 衛教宣傳、社區健檢、診篩等"];
export default {
  data() {
    return {
      avalible: false,
      model: {
        Identifier: "",
        CreateDate: null,
        Position: "",
        TCategory: "",
        Remark3: "",
        Target: "",
        TargetRemark: "",
        Remark5: "",
      },
      areas,
      enrollBy: 0,
      selectedAreas: [],
      selectedTargets: [],
      targets,
      userInput: "",
      userMonth: "",
      userTarget: "",
    };
  },
  async mounted() {
    let model;
    this.model.Identifier = this.$route.query.identifier;
    model = await window.axios.get(`/Training/GetHCPlane?Identifier=${this.model.Identifier}&CreateDate=${this.now()}`);
    if (model) {
      this.setModel(model);
    }
  },
  computed: {},
  methods: {
    setModel(model) {
      this.avalible = true;
      this.model.CreateDate = model.CreateDate;
      this.enrollBy = model.TCategory;
      this.userMonth = model.Remark3;
      this.userTarget = model.TargetRemark;
      this.userInput = model.Remark5;
      this.selectedAreas =
        (model.Position &&
          model.Position.split(",")
            .map((s, i) => (+s ? areas[i] : ""))
            .filter((s) => s)) ||
        [];

      this.selectedTargets =
        (model.Target &&
          model.Target.split(",")
            .map((s, i) => (+s ? targets[i] : ""))
            .filter((s) => s)) ||
        [];
    },
    async submit() {
      if (!this.selectedAreas.length) {
        this.$bvToast.toast(`請選擇 "見習單位"`, {
          title: "必選",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      if (!this.enrollBy.length) {
        this.$bvToast.toast(`請選擇 "見習方式"`, {
          title: "必選",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      if (this.selectedTargets.length < 2) {
        this.$bvToast.toast("見習目標至少兩項", {
          title: "必選",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }

      try {
        this.model.CreateDate = this.model.CreateDate ? this.model.CreateDate : this.now();
        this.model.Position = areas.map((s, i) => (this.selectedAreas.find((s2) => s2 == s) ? 1 : 0)).join(",");
        this.model.TCategory = this.enrollBy;
        this.model.Remark3 = this.userMonth;
        this.model.Target = targets.map((s, i) => (this.selectedTargets.find((s2) => s2 == s) ? 1 : 0)).join(",");
        this.model.TargetRemark = this.userTarget;
        this.model.Remark5 = this.userInput;

        const p = this.avalible ? await window.axios.put("/Training/PutPlane", this.model) : await window.axios.post("/Training/PostPlane", this.model);
        if (p) {
          this.$bvToast.toast("更新成功", {
            title: "成功",
            autoHideDelay: 5000,
            variant: "success",
          });
        } else {
          this.$bvToast.toast("更新失敗", {
            title: "失敗",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      } catch (e) {
        this.$bvToast.toast("服務器錯誤", {
          title: "失敗",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    now() {
      return moment().format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
.main-sec {
  margin: 0 auto;
}
.plan-header,
.main-body-header {
  background: #646b74;
  height: 42px;
  color: #fff;
  font-size: 20px;
  padding-left: 10px;
  line-height: 42px;
  text-align: center;
}

.plan-header {
  background: rgb(224, 224, 224);
  color: var(--dark);
  text-align: left;
}
.main-body-header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.main-body {
  border: 1px solid #bababa;
  border-top: none;
  padding: 10px;
}
.start-sec {
  width: 900px;
  margin: 0 auto;
}
.my-areas {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  gap: 12px;
  padding-left: 2rem;
  margin-top: 1rem;
}
.my-target {
  grid-template-columns: repeat(2, max-content);
}
.sec-2 {
  display: grid;
  grid-template-columns: repeat(14, max-content);
  gap: 1rem;
}
.sec-3 {
  height: 300px;
  grid-template-rows: max-content 1fr;
  gap: 1rem;
}
.footer-btn-areas {
  > * {
    float: right;
  }
}
</style>
